




































import { mixins } from 'vue-class-component/lib/util';
import { Component, Prop } from 'vue-property-decorator';
import { DateHelper } from '@/shared/helpers';
import { ValidationMixin } from '@/shared/mixins';
import moment from 'moment';

@Component({
    name: 'CeeDatePicker',
})
export default class CeeDatePicker extends mixins(ValidationMixin) {

    @Prop({ default: '' })
    public readonly label!: string;
    @Prop({ default: new Date().toISOString().substr(0, 10) })
    public readonly date!: string;
    @Prop({ default: () => new Array<() => boolean>(() => true) })
    public readonly rules!: Array<(value: string | any) => boolean | string>;
    @Prop({ default: false })
    public readonly outline!: boolean;
    @Prop({ default: false })
    public readonly requis!: boolean;
    @Prop({ default: false })
    public readonly enabled!: boolean;
    @Prop({ default: '' })
    public readonly inputName!: string;

    /**
     * Indique si l'input doit être désactivé.
     */
    @Prop({ default: false })
    public readonly disabled!: boolean;

    /**
     * La date min que l'on peut sélectionner
     */
    @Prop()
    public minDate: string;

    /**
     * La date min que l'on peut sélectionner
     */
    @Prop()
    public maxDate: string;

    private dateInternal!: string;

    /**
     * Indique si le picker de la date s'affiche.
     */
    private menu = false;

    private mounted(): void {
        this.dateInternal = this.date;
    }

    /**
     * La date minimum autorisée a être saisie au format anglais.
     */
    public get minAllowedDate(): string | undefined {
        return DateHelper.formatParam(this.minDate, 'YYYY-MM-DD');
    }
    
    /**
     * La date maximum autorisée a être saisie au format anglais.
     */
    public get maxAllowedDate(): string | undefined {
        return DateHelper.formatParam(this.maxDate, 'YYYY-MM-DD');
    }

    /**
     * Récupère la valeur de de la date au format FR.
     */
    public get computedDateFormatted(): string {
        if (!this.date) {
            return '';
        }
        return DateHelper.format(this.date);
    }

    /**
     * Sélection d'une date via le textfield.
     * @param dateSaisie Date écrite manuellement par l'utilisateur.
     */
    public onTextInput(dateSaisie: string): void {

        // Si la date a été vidée, on transmet l'information.
        if (!dateSaisie) {
            this.$emit('dateUpdated', '');
        }

        // Si la date saisie est valide, on la transforme dans le format par défaut et on l'envoie via la même méthode que si on était passé par le datepicker.
        const date = moment(dateSaisie, DateHelper.formatDateFR, true);
        if (date.isValid()) {
            this.$emit('dateUpdated', date.format(DateHelper.formatDateParDefaut));
        }

        // Ferme le menu quand on commence à écrire dans le champ.
        this.menu = false;
    }
    

    /**
     * Sélection d'une date via le datepicker.
     * @param dateValue Date sélectionnée.
     */
    public onDatePickerInput(dateValue: string): void {
        this.menu = false;
        this.$emit('dateUpdated', dateValue);
    }

    /**,
     * Rules incluant une vrai vérification de la date saisie.
     */
    public get rulesAvecDateValide() {
        // La vérification de la date valide.
        const ruleDateValide: ((value: string | any) => boolean | string)[] = [(v: string | any) => this.isDateValide(v) || 'Date invalide'];

        // Au passage on vérifie aussi les dates minimale et maximal si il y en a.
        if (this.minDate) {
            ruleDateValide.push((v: string) => !v ||
                !this.isDateValide(v) || // Cette ligne c'est pour éviter de comparer des dates qui n'en sont pas. La validité de la date est déjà vérifiée avec la règle au dessus.
                DateHelper.premiereSuperieurASecondeWithoutTime(DateHelper.frToIso(v), this.minDate, true) ||
                `Date inférieure à la date minimale : ${DateHelper.format(this.minDate)}`);
        }
        if (this.maxDate) {
            const today = DateHelper.format(DateHelper.todayIso());
            const dateMax = DateHelper.format(this.maxDate);
            let message: string = "";
            if (dateMax !== today) {
                message = `Vous devez saisir une date inférieur à la date de fin d'arrêté du ${dateMax}`
            }
            else {
                message = `Date supérieure à la date maximale : ${dateMax}`
            };
            ruleDateValide.push((v: string) => !v ||
                !this.isDateValide(v) ||
                DateHelper.premiereSuperieurASecondeWithoutTime(DateHelper.frToIso(dateMax), DateHelper.frToIso(v), true) ||
                message);
        }

        // Et on ajoute aux rules existantes si il y en a.
        return this.rules === undefined ? ruleDateValide : this.rules.concat(ruleDateValide);
    }
}

import { Component, Mixins } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import template from './ImportFichierRetour.Template.vue';
import ImportFichierRetourValidator from './ImportFichierRetour.Validator';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { ApiService } from '@/services/base/ApiService';

@Component({
    ...template,
    name: 'ImportFichierRetour',
    components: {
        CeeDatePicker,
        ValidationProvider,
        ValidationObserver,
    },
})
export default class ImportFichierRetour extends Mixins(ImportFichierRetourValidator, GrilleMixin) {
    public $refs!: {
        form: HTMLFormElement,
    };

    // Loader.
    private loading: boolean = false;

    private file: File;

    /**
     * constructor.
     */
    constructor() {
        super();
    }

    /**
     * Création d'un nouveau dépôt.
     */
    private modificationOperationsEchantillon(): void {
        this.loading = true;
        const modificationOperationsEchantillonApiService = new ApiService<FormData>('/echantillon/updateImportFichierRetour');
        if (this.$refs.form.validate()) {            
            const formData = new FormData();
            formData.append(this.file.name, this.file);
            modificationOperationsEchantillonApiService.post(formData).finally(() => this.loading = false);
        }
    }
}

